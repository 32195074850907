export const SET_FIRST_PAGE_VISITED = "SET_FIRST_PAGE_VISITED";

export const actions = {
  setFirstPageVisited: (value) => (dispatch) => {
    dispatch({
      type: SET_FIRST_PAGE_VISITED,
      payload: {
        value: value
      }
    })
  }
};
