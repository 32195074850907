'use client';

import { Provider } from "react-redux";
import { configureStore } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";


// Redux setup
export const store = configureStore();
export const persistor = persistStore(store);


export const StoreProvider = ({ children }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={<div />} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};
