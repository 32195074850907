import { getModules } from "../../config/config";
import { actions as GlobalActions } from "./GlobalActions";

export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const GET_MODULES_REQUEST = "GET_MODULES_REQUEST";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_ERROR = "GET_MODULES_ERROR";

export const SET_EXCLUDED_MODULES = "SET_EXCLUDED_MODULES";

export const actions = {
  setOrganization: (value, previousValue) => (dispatch) => {
    if (value !== previousValue) {
      dispatch(GlobalActions.clearAllData());
    }
    dispatch({
      type: SET_ORGANIZATION,
      payload: {
        value: value
      }
    })
  },
  getModules: (onError) => async (dispatch) => {
    dispatch({ type: GET_MODULES_REQUEST });
    try {
      const res = await getModules();
      if (res.status === 200) {
        dispatch({
          type: GET_MODULES_SUCCESS,
          payload: {
            activatedModules: res?.data?.activatedModules,
            allModules: res?.data?.allModules,
          },
        });
      } else {
        dispatch({
          type: GET_MODULES_ERROR,
        });
        onError(res?.data?.message || "Get Modules error"); // call the error callback
      }
    } catch (error) {
      dispatch({
        type: GET_MODULES_ERROR,
      });
      onError(error?.res?.data?.message || "Get Modules error"); // call the error callback
    }
  },
  setExcludedModules: (value) => (dispatch) => {
    dispatch({
      type: SET_EXCLUDED_MODULES,
      payload: {
        value: value
      }
    })
  }
};
