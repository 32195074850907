import { getTranslation, getLanguages } from '../../config/config';
import { notify } from '../../utils/notify';
import { SET_LANGUAGE } from './LanguageActions';
import Cookies from 'js-cookie';


export const GET_TRANSLATION_REQUEST = 'GET_TRANSLATION_REQUEST';
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS';
export const GET_TRANSLATION_ERROR = 'GET_TRANSLATION_ERROR';

export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_ERROR = 'GET_LANGUAGES_ERROR';

export const actions = {
  // initializeTranslation: (translation) => async (dispatch, getState) => {
  //   dispatch({ type: GET_TRANSLATION_REQUEST });

  //   try {
  //     if (!translation || translation.length === 0) {
  //       notify.error('No translation data available');
  //       dispatch({ type: GET_TRANSLATION_ERROR });
  //       return;
  //     }

  //     const currentLanguage = getState().language.language;
  //     const supportedLanguages = translation.map(lang => ({
  //       name: lang.code,
  //       isDefault: lang.isDefault,
  //     }));

  //     const defaultLanguage = supportedLanguages.find(lang => lang.isDefault)?.name || supportedLanguages[0]?.name;

  //     if (!currentLanguage || !supportedLanguages.some(lang => lang.name.toLowerCase() === currentLanguage.toLowerCase())) {
  //       dispatch({ type: SET_LANGUAGE, payload: defaultLanguage });
  //     }

  //     dispatch({
  //       type: GET_TRANSLATION_SUCCESS,
  //       payload: { supportedLanguages, translation },
  //     });

  //     Cookies.set("translation", JSON.stringify(translation));
  //   } catch (error) {
  //     notify.error('Translation initialization error');
  //     dispatch({ type: GET_TRANSLATION_ERROR });
  //   }
  // },
  getTranslation: () => async (dispatch) => {
    dispatch({ type: GET_TRANSLATION_REQUEST });

    try {
      const res = await getTranslation();
      if (res?.status === 200) {
        const translation = res?.data?.translations[0]?.languages;
        dispatch({
          type: GET_TRANSLATION_SUCCESS,
          payload: {
            translation: translation
          },
        });
        Cookies.set("translation", JSON.stringify(translation));
      } else {
        const errorMessage = res?.data?.message || 'Get Translation error';
        notify.error(errorMessage);
        dispatch({ type: GET_TRANSLATION_ERROR });
      }
    } catch (error) {
      notify.error('Get Translation error');
      dispatch({ type: GET_TRANSLATION_ERROR });
    }
  },
  getLanguages: () => async (dispatch, getState) => {
    dispatch({ type: GET_LANGUAGES_REQUEST });

    try {
      const res = await getLanguages();
      if (res?.status === 200) {
        const currentLanguage = getState().language.language;

        const supportedLanguages = res?.data?.languages

        const defaultLanguage = supportedLanguages?.find((lang) => {
          return lang?.isDefault
        })
        let value;
        if (!currentLanguage || (currentLanguage && !supportedLanguages?.find((lang) => {
          return lang?.name?.toLowerCase() == currentLanguage?.toLowerCase()
        }))) {
          value = defaultLanguage ? defaultLanguage?.name : supportedLanguages?.length > 0 ? supportedLanguages[0]?.name : "";
          dispatch({
            type: SET_LANGUAGE,
            payload: value
          })
          Cookies.set("defaultLanguage", JSON.stringify(value));
        } else {
        Cookies.set("defaultLanguage", JSON.stringify(defaultLanguage));
        }

        dispatch({ 
          type: GET_LANGUAGES_SUCCESS,
          payload: {
            supportedLanguages: supportedLanguages
          },
        });
      } else {
        const errorMessage = res?.data?.message || 'Get Languages error';
        notify.error(errorMessage);
        dispatch({ type: GET_LANGUAGES_ERROR });
      }
    } catch (error) {
      notify.error('Get Languages error');
      dispatch({ type: GET_LANGUAGES_ERROR });
    }
  }
};


