import { actions as GlobalActions } from "./GlobalActions";
import Cookies from "js-cookie";


export const SET_APP_SOURCE = "SET_APP_SOURCE";
export const RESET_APP_SOURCE = "RESET_APP_SOURCE";

export const actions = {
  setAppSource: (value) => (dispatch) => {
    dispatch({
      type: SET_APP_SOURCE,
      payload:
      {
        value: value
      }
      
    });
    Cookies.set("appSource", value);
  },
  resetAppSource: () => (dispatch) => {
    dispatch({
      type: RESET_APP_SOURCE
    })
  }
};