import {
  SET_FIRST_PAGE_VISITED
} from "../actions/FirstPageVisited.Actions";

const initialState = {
 firstPageVisited: true
};

function firstPageVisited(state = initialState, action) {
  switch (action.type) {
    case SET_FIRST_PAGE_VISITED:
      return Object.assign({}, state, {
        firstPageVisited: action?.payload?.value
      })
    default:
      return state;
  }
}

export default firstPageVisited;
